@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.stepper {
  .mat-step-label {
    display: none;
  }

  .mat-stepper-horizontal-line,
  &.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
  &.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after {
    border-top-color: #ecf1f7;
    border-top-width: 2px;
  }

  &.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
  &.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after {
    width: calc(50% - 12px);
  }

  .mat-step-header {
    &.mat-horizontal-stepper-header {
      padding-left: 0;
      padding-right: 0;
    }

    .mat-step-icon {
      background-color: #{map.get(theme.$accent-palette, 50)};
      color: #{map.get(theme.$primary-palette, 200)};
    }

    .mat-step-icon-selected {
      background-color: transparent;
      border-color: #{map.get(theme.$primary-palette, 100)};
      border-style: solid;
      border-width: 1.5px;
      color: #{map.get(theme.$primary-palette, 100)};
    }

    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      background-color: #{map.get(theme.$primary-palette, 100)};
      color: #{map.get(theme.$primary-palette, 'contrast', 900)};
    }

    &.cdk-keyboard-focused,
    &.cdk-program-focused,
    &:hover:not([aria-disabled]),
    &:hover[aria-disabled='false'] {
      background-color: unset;
    }
  }

  @media screen and (min-width: 600px) {
    .mat-step-label {
      display: block;
    }
  }
}

.mat-stepper-horizontal {
  .mat-step-header .mat-step-label,
  .mat-step-label-selected {
    font-size: 12px;
    font-weight: 400;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }
}

@mixin step-completed {
  border-top-color: #{map.get(theme.$primary-palette, 100)} !important;
}

.completed-until-1 .mat-horizontal-stepper-header-container {
  *:nth-child(1)::after,
  *:nth-child(2),
  *:nth-child(3)::before {
    @include step-completed;
  }
}

.completed-until-2 .mat-horizontal-stepper-header-container {
  *:nth-child(1)::after,
  *:nth-child(2),
  *:nth-child(3)::before,
  *:nth-child(3)::after,
  *:nth-child(4),
  *:nth-child(5)::before {
    @include step-completed;
  }
}

.completed-until-3 .mat-horizontal-stepper-header-container {
  *:nth-child(1)::after,
  *:nth-child(2),
  *:nth-child(3)::before,
  *:nth-child(3)::after,
  *:nth-child(4),
  *:nth-child(5)::before,
  *:nth-child(5)::after,
  *:nth-child(6),
  *:nth-child(7)::before {
    @include step-completed;
  }
}
