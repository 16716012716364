@mixin severity($color) {
  margin-bottom: 0;

  .mdc-snackbar__surface {
    background: #fff !important;
    border-bottom: 2px solid $color;
    border-radius: 5px 5px 0 0;
  }

  .mat-icon:not(.snkbar__closelinkicon) {
    color: $color;
  }

  .snkbar__content,
  .mat-mdc-simple-snackbar-action {
    color: #121212;
  }
}

.mat-mdc-snack-bar-container {
  margin: 8px auto !important;
  max-width: 344px !important;
  min-width: unset !important;
  padding-bottom: 12px !important;
  padding-top: 12px !important;

  @media (min-width: 600px) {
    margin: 24px 8px !important;
  }

  &.snackbar {
    &--success {
      @include severity(rgb(var(--notification-success-color)));
    }

    &--failure {
      @include severity(rgb(var(--notification-error-color)));
    }

    &--warning {
      @include severity(rgb(var(--notification-warning-color)));
    }

    &--neutral {
      @include severity(rgb(var(--notification-info-color)));
    }
  }
}
