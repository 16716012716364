.mdc-switch {
  // ----------------------------------------------------- //
  // - Disabled - //
  // ----------------------------------------------------- //

  // --- General --- //
  --mdc-switch-disabled-handle-opacity: 1 !important;
  --mdc-switch-disabled-selected-icon-opacity: 1 !important;
  --mdc-switch-disabled-track-opacity: 1 !important;
  --mdc-switch-disabled-unselected-icon-opacity: 1 !important;

  // --- Unselected --- //
  --mdc-switch-disabled-unselected-track-color: #eee;
  --mdc-switch-disabled-unselected-handle-color: #e0e1e2;

  // --- Selected --- //
  --mdc-switch-disabled-selected-track-color: #eee;
  --mdc-switch-disabled-selected-handle-color: #e0e1e2;

  // ----------------------------------------------------- //
  // - Enabled - //
  // ----------------------------------------------------- //

  // --- Unselected --- //
  --mdc-switch-unselected-track-color: #eee;
  --mdc-switch-unselected-handle-color: #6e6e6e;
  --mdc-switch-unselected-hover-track-color: #eee;
  --mdc-switch-unselected-hover-handle-color: #6e6e6e;
  --mdc-switch-unselected-focus-track-color: #eee;
  --mdc-switch-unselected-focus-handle-color: #6e6e6e;
  --mdc-switch-unselected-pressed-track-color: #eee;
  --mdc-switch-unselected-pressed-handle-color: #6e6e6e;

  // --- Selected --- //
  --mdc-switch-selected-track-color: #{map.get(theme.$primary-palette, 200)};
  --mdc-switch-selected-handle-color: #6e6e6e;
  --mdc-switch-selected-hover-track-color: #{map.get(theme.$primary-palette, 200)};
  --mdc-switch-selected-hover-handle-color: #6e6e6e;
  --mdc-switch-selected-focus-track-color: #{map.get(theme.$primary-palette, 200)};
  --mdc-switch-selected-focus-handle-color: #6e6e6e;
  --mdc-switch-selected-pressed-track-color: #{map.get(theme.$primary-palette, 200)};
  --mdc-switch-selected-pressed-handle-color: #6e6e6e;
}
