:root {
  --swiper-navigation-size: 25px;
}

.swiper {
  padding-bottom: 18px;

  .swiper-pagination-bullet {
    background-color: #e4e4e4;

    &.swiper-pagination-bullet-active {
      background-color: #c4c4c4;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    z-index: 998;
    top: calc(100% - 20px);
  }

  .swiper-button-prev {
    right: 30px;
    left: auto;
  }
}

.swiper-navigation {
  display: flex;
  gap: 5px;
  justify-content: right;
  align-items: center;
  padding: 0 15px;

  > .swiper-item-description {
    flex-grow: 1;
    text-align: center;
  }
}

.gallery-wrapper {
  overflow: hidden;
  width: 100%;

  .gallery-default-container,
  .gallery-list-container,
  .gallery-preview-container {
    &.zoomable {
      .gallery-item {
        cursor: pointer;
      }
    }

    .gallery-item {
      aspect-ratio: 16 / 9;
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;

      > * {
        height: 100% !important;
        position: absolute !important;
        width: auto !important;
      }

      .video-slider-item {
        width: 100% !important;
      }
    }
  }

  .gallery-default-container {
    position: relative;

    .gallery-item {
      > * {
        height: auto !important;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .swiper-navigation {
      cursor: default;
      margin-bottom: 8px;
      margin-top: 3px;

      button {
        background-color: #fff;
        border: 1px solid #e0e0e0;
        outline: none;

        &:not(.disabled) {
          cursor: pointer;
        }

        &.previous {
          border-radius: 0 4px 4px 0;
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        &.next {
          border-radius: 4px 0 0 4px;
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        &.disabled {
          color: #d4d3d6;
        }
      }
    }
  }

  .gallery-preview-container {
    position: relative;

    .swiper-navigation {
      cursor: default;
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      justify-content: left;
      margin-top: 4px;
      padding: 0;

      .swiper-item-preview-toggle {
        align-items: center;
        aspect-ratio: 1 / 1;
        background-color: rgb(var(--palette-background-grey));
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        position: relative;
        top: -3px;
        width: 20%;
        transition-duration: 0.2s;

        &:hover,
        &:focus {
          background-color: rgb(var(--palette-background-app-bar-darken));
        }
      }

      .swiper-item-preview {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 20%;

        .swiper-item-active {
          > * {
            border: 2px solid transparent;
            width: calc(100% - 4px);
          }

          &.show {
            > * {
              border: 2px solid rgb(0 0 0 / 50%);
            }
          }
        }
      }
    }

    .swiper-item-description {
      bottom: 0;
      font-size: 12px;
      left: 0;
      padding: 8px 0;
      text-align: center;
      width: 100%;
      z-index: 10;
    }
  }

  .gallery-list-container {
    padding: 16px 0;
    position: relative;

    &.aspect-less {
      .gallery-content {
        .gallery-item {
          aspect-ratio: unset;

          .gallery-item-inner {
            position: relative !important;
            width: 100% !important;

            .brands-wrapper {
              width: 100% !important;
            }
          }
        }
      }
    }

    .gallery-content {
      display: flex;
      flex-wrap: wrap;
      gap: 8px 0;
      margin: 0 -8px;
      overflow: hidden;

      .gallery-item {
        width: 25%;

        .gallery-item-inner {
          align-items: center;
          display: flex;
          justify-content: center;
          margin: 0 8px;
          overflow: hidden;

          img {
            min-width: 100%;
            min-height: 100%;
          }
        }

        @media (max-width: 1239px) {
          width: 33.33%;
        }
      }
    }

    .gallery-expand {
      .expandable-button {
        color: rgba(var(--palette-foreground-text), var(--palette-foreground-text-alpha));
        cursor: pointer;
        display: inline-block;
        margin-bottom: 8px;
      }
    }
  }
}

[data-description]::before {
  content: attr(data-description);
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
}
