@for $i from 1 through 12 {
  .mdc-layout-grid__cell--start-#{$i} {
    grid-column-start: #{$i};
  }

  .mdc-layout-grid__cell--gap-#{$i} {
    grid-column-gap: #{$i};
  }

  .mdc-layout-grid__cell--end-#{$i} {
    grid-column-end: #{$i};
  }

  @media (min-width: 840px) {
    .mdc-layout-grid__cell--start-#{$i}-desktop {
      grid-column-start: #{$i};
    }

    .mdc-layout-grid__cell--gap-#{$i}-desktop {
      grid-column-gap: #{$i};
    }

    .mdc-layout-grid__cell--end-#{$i}-desktop {
      grid-column-end: #{$i};
    }
  }

  @media (min-width: 600px) and (max-width: 839px) {
    .mdc-layout-grid__cell--start-#{$i}-tablet {
      grid-column-start: #{$i};
    }

    .mdc-layout-grid__cell--gap-#{$i}-tablet {
      grid-column-gap: #{$i};
    }

    .mdc-layout-grid__cell--end-#{$i}-tablet {
      grid-column-end: #{$i};
    }
  }

  @media (max-width: 599px) {
    .mdc-layout-grid__cell--start-#{$i}-phone {
      grid-column-start: #{$i};
    }

    .mdc-layout-grid__cell--gap-#{$i}-phone {
      grid-column-gap: #{$i};
    }

    .mdc-layout-grid__cell--end-#{$i}-phone {
      grid-column-end: #{$i};
    }
  }
}
