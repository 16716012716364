@use 'sass:map' as map;
@use './theme' as theme;

@import './button';
@import './stepper';
@import './chips';
@import './snackbar';
@import './grid';
@import './tabs';
@import './drawer';
@import './list';
@import './card';
@import './dialog';
@import './checkbox';
@import './icon';
@import './accordion';
@import './bottom-sheet';
@import './radio-button';
@import './form-field';
@import './calendar';
@import './toggle';

@include theme.mat-all-component-themes(theme.$theme);
@include theme.mat-typography-hierarchy(theme.$typography);

a {
  @include theme.mat-m2-typography-level(theme.$typography, 'body-1');

  color: #{map.get(theme.$primary-palette, 200)};
}
