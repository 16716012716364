@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.mat-focused .mat-form-field-label .mat-form-field-required-marker {
  color: #{map.get(theme.$primary-palette, 200)} !important;
}

.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: rgb(var(--palette-warn-default)) !important;
}
