@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.mat-calendar {
  margin: auto;
  max-width: 450px;

  @media (min-width: 600px) {
    width: 330px;
  }

  .mat-calendar-header .mat-calendar-controls {
    margin: 0;
  }

  .mat-calendar-body-selected {
    background-color: var(--palette-highlight-default);
    color: #{map.get(theme.$primary-palette, 200)};
  }

  .mat-calendar-body-in-range::before {
    background-color: rgba(var(--palette-highlight-default-rgb), 0.3);
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled) {
    &:hover > .mat-calendar-body-cell-content {
      &:not(.mat-calendar-body-selected.mat-calendar-body-comparison-identical) {
        background-color: rgba(var(--palette-highlight-default-rgb), 0.5);
      }
    }
  }
}
