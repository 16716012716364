.mat-mdc-card {
  &.planone-custom {
    box-shadow: 0 8px 20px rgb(0 0 0 / 6%) !important;

    img {
      &.brand {
        max-width: 100%;
      }
    }
  }
}
