.mat-nav-list {
  .mat-list-item-content {
    justify-content: space-between;

    .list-item-text {
      padding-left: 1rem;
      text-transform: uppercase;
      width: 100%;
    }
  }
}
