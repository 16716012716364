.mat-bottom-sheet-container {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  height: 75vh;
  min-width: unset !important;
  overflow-x: hidden !important;
  width: 100vw;

  > .ng-star-inserted {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .mat-mdc-dialog-title {
    .flex-align {
      margin-top: 0 !important;
    }
  }

  .mat-mdc-dialog-content {
    display: block;
    flex-grow: 1;
    margin: 0 -1rem;
    max-height: 65vh;
    overflow: auto;
    padding: 0 1.5rem;
  }

  .mat-mdc-dialog-actions {
    margin-bottom: 0;
    margin-top: 1rem;
    min-height: 90px;
  }
}
