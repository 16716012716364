.mat-accordion {
  > .mat-expansion-panel {
    box-shadow: var(--expansion-panel-box-shadow) !important;
    margin: var(--expansion-panel-margin);

    .mat-expansion-panel-header {
      border-top: 1px solid rgb(var(--expansion-panel-header-border-color));
      height: var(--expansion-panel-header-height);
      padding: var(--expansion-panel-header-padding);

      &:hover {
        background-color: var(--expansion-panel-header-background-color-hover) !important;
      }
    }

    &:first-child {
      .mat-expansion-panel-header {
        border-top: var(--expansion-panel-header-border-top-first-child);
      }
    }

    .mat-expansion-panel-body {
      padding: var(--expansion-panel-body-padding);
    }
  }
}
