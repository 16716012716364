@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.mat-mdc-chip {
  hyphens: auto;

  &.cdk-focused .mat-mdc-chip-focus-overlay {
    opacity: 0 !important;
  }

  &.mat-mdc-standard-chip {
    --mdc-chip-label-text-size: 14px;

    &.mdc-evolution-chip--selectable .mat-mdc-chip-action-label {
      align-items: center;
      display: flex;
      font-weight: 400;
    }

    &.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
      padding: 0;
    }

    &.mat-mdc-chip-option {
      background-color: rgb(var(--palette-background-unselected-chip));
    }

    &.mat-primary {
      border: 1px solid #{map.get(theme.$primary-palette, 200)};
      font-size: 14px;
      font-weight: 400;
      height: unset;

      .mat-icon {
        color: #{map.get(theme.$primary-palette, 200)};
        margin-left: 4px;
      }

      &.mat-mdc-chip-selected {
        background-color: #{map.get(theme.$primary-palette, 200)};
      }

      &.nonselectable {
        background-color: var(--palette-background-non-selectable-chip);
        border: 1px solid #{map.get(theme.$primary-palette, 200)};
        font-size: 14px;
        font-weight: 400;
        padding-left: 12px;
        pointer-events: none;

        .mdc-evolution-chip__text-label {
          color: rgb(var(--palette-color-non-selectable-chip));
        }
      }
    }
  }
}
