@mixin variables {
  /* Element specific custom definitions */
  --logo-border-color: 224, 224, 224;
  --logo-border-radius: 2px;
  --logo-width: 100px;

  --exhibitor-chip-border-color: 228, 228, 228;
  --exhibitor-chip-color: 0, 0, 0, 0.87;
  --exhibitor-chip-color-highlighted: 255, 255, 255, 1;
  --exhibitor-chip-font-size: 12px;
  --exhibitor-chip-font-weight: 500;
  --exhibitor-chip-line-height: 24px;
  --exhibitor-chip-padding-x: 0;
  --exhibitor-chip-padding-y: 12px;
  --exhibitor-chip-border-radius: 12px;

  --info-box-background-color: 241, 245, 250;

  --body-background-color: 247, 247, 247;

  --expansion-panel-box-shadow: none;
  --expansion-panel-margin: 0;
  --expansion-panel-header-background-color-hover: transparent;
  --expansion-panel-header-border-color: 33 33 33 / 8%;
  --expansion-panel-header-border-top-first-child: 0;
  --expansion-panel-header-height: 48px;
  --expansion-panel-header-padding: 8px 0;
  --expansion-panel-body-padding: 0 0 16px;

  --teaser-badge-line-height: 16px;
  --teaser-badge-font-size: 11px;
  --teaser-badge-font-weight: 400;
  --teaser-content-font-weight: 300;
  --teaser-content-line-height: 18px;
  --teaser-actions-line-height: 21px;

  --footer-color-xs: #fff;
  --card-link-color-xs: #fff;
  --stroke-color: 224, 225, 226;

  --headline-margin: 16px;

  --mdc-checkbox-selected-checkmark-color: #fff;

  --mdc-typography-button-text-transform: uppercase;
}
