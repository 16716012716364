@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.p1-cc {
  .cc-allow {
    background: #{map.get(theme.$primary-palette, 200)};
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    color: #{map.get(theme.$primary-palette, 'contrast', 200)};
    cursor: pointer;
    display: inline-block;
    font-weight: 300;
    margin: 0;
    min-width: 64px;
    outline: none;
    overflow: visible;
    padding: 0 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;
    vertical-align: baseline;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;

    &:hover,
    &:focus {
      background: #{map.get(theme.$primary-palette, 400)};
      color: #{map.get(theme.$primary-palette, 'contrast', 400)};
    }
  }
}
