@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.mat-icon {
  color: rgb(var(--palette-icon-color));
  fill: rgb(var(--palette-icon-color));

  &.action__icon,
  &.actions__icon {
    color: #{map.get(theme.$primary-palette, 200)};
  }

  .stepper .mat-step-header & {
    color: #{map.get(theme.$primary-palette, 'contrast', 200)};
  }
}
