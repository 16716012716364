@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.cdk-overlay-backdrop {
  backdrop-filter: blur(4px);
}

.mat-mdc-dialog-actions {
  display: block !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-font: #{theme.$font};
  --mdc-dialog-supporting-text-color: rgba(var(--palette-foreground-text), var(--palette-foreground-text-alpha));
}
