.mat-drawer-backdrop {
  &.mat-drawer-shown {
    background-color: transparent !important;
  }
}

.sidenav-container__inner {
  .mat-drawer-backdrop {
    &.mat-drawer-shown {
      background-color: rgba(var(--palette-sidebar-backdrop), 0.6) !important;
    }
  }
}
