@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #{map.get(theme.$primary-palette, 200)};
  --mat-tab-header-active-label-text-color: #{map.get(theme.$primary-palette, 200)};
  --mat-tab-header-active-ripple-color: #{map.get(theme.$primary-palette, 200)};
  --mat-tab-header-inactive-ripple-color: #{map.get(theme.$primary-palette, 200)};
  --mat-tab-header-active-focus-label-text-color: #{map.get(theme.$primary-palette, 200)};
  --mat-tab-header-active-hover-label-text-color: #{map.get(theme.$primary-palette, 200)};
  --mat-tab-header-active-focus-indicator-color: #{map.get(theme.$primary-palette, 200)};
  --mat-tab-header-active-hover-indicator-color: #{map.get(theme.$primary-palette, 200)};
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 12%);

  .mat-mdc-tab-labels {
    .mdc-tab--active .mdc-tab__text-label {
      color: rgba(var(--palette-foreground-text), var(--palette-foreground-text-alpha));
    }

    .mat-mdc-tab-label {
      padding: 0;
    }

    .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
      border-color: #{map.get(theme.$primary-palette, 500)};
    }

    .mat-mdc-tab {
      min-width: 72px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      text-transform: uppercase;
      @media (min-width: 600px) {
        min-width: 160px;
        padding-left: inherit;
        padding-right: inherit;
      }
    }
  }
}

.mdc-tabs {
  .mdc-tabs-shrink {
    .mat-mdc-tab-header {
      border-bottom: 0 !important;
      margin-bottom: 72px;
    }
  }
}

.mdc-tabs-spread {
  .mat-mdc-tab-list {
    .mat-mdc-tab-labels {
      .mat-mdc-tab-label {
        flex-grow: 1;
      }
    }
  }
}
