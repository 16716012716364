@use 'sass:map';
@use 'sass:color';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

$map: map;

.mdc-button {
  height: 40px !important;
  min-width: unset !important;
}

.mat-mdc-button {
  padding: 0 16px;
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #{map.get(theme.$primary-palette, 500)};
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #{map.get(theme.$primary-palette, 500)};
}

.mat-mdc-unelevated-button {
  &.mat-primary {
    --mdc-filled-button-container-color: #{map.get(theme.$primary-palette, 500)};
    --mdc-filled-button-label-text-color: #{map.get(theme.$primary-palette, 'contrast', 500)};
  }

  > .mat-icon {
    font-size: 0.875rem;
  }
}

.mat-mdc-button .mat-mdc-button-persistent-ripple,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  display: none;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 40px;
}

.mat-mdc-icon-button svg {
  height: 100% !important;
  width: 100% !important;
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;

  &[disabled] {
    --mdc-text-button-label-text-color: rgb(var(--palette-foreground-disabled-button-dark));

    &.mat-secondary {
      box-shadow: inset 0 0 0 2px color.mix(#fff, map.get(theme.$primary-palette, 200), 50%) !important;
    }
  }

  &.mat-secondary {
    box-shadow: inset 0 0 0 2px #{map.get(theme.$primary-palette, 200)} !important;
  }

  &.mat-primary,
  &.mat-secondary {
    --mdc-filled-button-label-text-transform: uppercase;
    --mdc-outlined-button-label-text-transform: uppercase;
    --mdc-text-button-label-text-transform: uppercase;
  }
}

.mat-button,
.mat-flat-button {
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;

  &.mat-button-disabled {
    color: rgb(var(--palette-foreground-disabled-button-dark));
  }

  &.mat-secondary {
    box-shadow: inset 0 0 0 2px #{map.get(theme.$primary-palette, 200)} !important;
  }
}
