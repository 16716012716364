[id^='sas_container_'] {
  max-width: 100%;

  [id^='sas_divFix_'] {
    max-width: calc(100% - 32px);

    [id^='img_'] {
      height: auto !important;
      max-width: 100%;
    }
  }
}

body > div[id^='117559-'],
body > div[id^='117558-'],
body > div[id^='24905-'] {
  display: none;
}
