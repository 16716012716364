@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.mdc-radio {
  &__background::before {
    background-color: #{map.get(theme.$primary-palette, 200)} !important;
  }

  &__outer-circle,
  &__inner-circle {
    border-color: #{map.get(theme.$primary-palette, 200)} !important;
  }
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: #{map.get(theme.$primary-palette, 200)} !important;
}

.mat-mdc-dialog-container {
  .mat-mdc-radio-group {
    .mat-mdc-radio-button {
      @media (max-width: 839px) {
        .mat-mdc-radio-label {
          white-space: normal;
        }
      }
    }
  }
}

.mat-bottom-sheet-container {
  .mat-mdc-radio-group {
    align-items: stretch;
    display: flex;
    flex-direction: column;

    .mat-mdc-radio-button {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(var(--palette-foreground-thin-border));
      }
    }
  }
}
