@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

body {
  /**
   * Material palette background colors
   */
  --palette-background-highlight: linear-gradient(
    90deg,
    #{map.get(theme.$accent-palette, 100)},
    #{map.get(theme.$accent-palette, 200)}
  );
  --palette-background-background: #fff;
  --palette-background-status-bar: 224, 224, 224;
  --palette-background-purple: 248, 242, 251;
  --palette-background-blue: 246, 248, 252;
  --palette-background-grey: 249, 249, 250;
  --palette-background-field: 237, 237, 237;
  --palette-background-app-bar-darken: 222, 222, 222;
  --palette-background-hover: 246, 249, 252;
  --palette-background-hover-alpha: 1;
  --palette-background-card: 255, 255, 255;
  --palette-background-dialog: 255, 255, 255;
  --palette-background-disabled-button: 0, 0, 0;
  --palette-background-disabled-button-alpha: 0.12;
  --palette-background-raised-button: 255, 255, 255;
  --palette-background-focused-button: 0, 0, 0;
  --palette-background-focused-button-alpha: 0.12;
  --palette-background-selected-button: 224, 224, 224;
  --palette-background-selected-disabled-button: 189, 189, 189;
  --palette-background-disabled-button-toggle: 238, 238, 238;
  --palette-background-non-selectable-chip: #{map.get(theme.$primary-palette, 'contrast', 200)};
  --palette-color-non-selectable-chip: #{map.get(theme.$primary-palette, 200)};
  --palette-background-unselected-chip: 255, 255, 255, 1;
  --palette-background-disabled-list-option: 238, 238, 238;
  --palette-background-tooltip: 51, 51, 51;
  --palette-background-status-bar-dark: 0, 0, 0;
  --palette-background-app-bar-dark: 33, 33, 33;
  --palette-background-background-dark: 48, 48, 48;
  --palette-background-hover-dark: 255, 255, 255;
  --palette-background-hover-dark-alpha: 0.04;
  --palette-background-card-dark: 66, 66, 66;
  --palette-background-dialog-dark: 66, 66, 66;
  --palette-background-disabled-button-dark: 255, 255, 255;
  --palette-background-disabled-button-dark-alpha: 0.12;
  --palette-background-raised-button-dark: 66, 66, 66;
  --palette-background-focused-button-dark: 255, 255, 255;
  --palette-background-focused-button-dark-alpha: 0.12;
  --palette-background-selected-button-dark: 33, 33, 33;
  --palette-background-selected-disabled-button-dark: 66, 66, 66;
  --palette-background-disabled-button-toggle-dark: 0, 0, 0;
  --palette-background-unselected-chip-dark: 97, 97, 97;
  --palette-background-disabled-list-option-dark: 0, 0, 0;
  --palette-background-tooltip-dark: 97, 97, 97;
  --palette-background-top-of-the-list-gradient-dark: 200, 224, 255;
  --palette-background-filter-chips: #{map.get(theme.$primary-palette, 200)};
  --palette-background-filter-chips-alpha: 1;
  --palette-background-filter-chips-border: #{map.get(theme.$primary-palette, 200)};
  --palette-background-filter-chips-border-alpha: 0;
  --palette-background-filter-chips-remove-button: 255, 255, 255;
  --palette-background-filter-chips-remove-button-alpha: 1;
  --palette-background-filter-chips-toggle: 0, 0, 0;
  --palette-background-filter-chips-toggle-alpha: 0;
  --palette-background-filter-chips-toggle-border: 204, 204, 204;
  --palette-background-filter-chips-toggle-border-alpha: 1;
  --palette-background-watchlist-item-border: 228, 228, 228;
  --palette-foreground-base: 0, 0, 0;
  --palette-foreground-divider: 0, 0, 0;
  --palette-foreground-divider-alpha: 0.12;
  --palette-foreground-dividers: 0, 0, 0;
  --palette-foreground-dividers-alpha: 0.12;
  --palette-foreground-disabled: 0, 0, 0;
  --palette-foreground-disabled-alpha: 0.38;
  --palette-foreground-disabled-button: 0, 0, 0;
  --palette-foreground-disabled-button-alpha: 0.26;
  --palette-foreground-disabled-text: 0, 0, 0;
  --palette-foreground-disabled-text-alpha: 0.38;
  --palette-foreground-hint-text: 0, 0, 0;
  --palette-foreground-hint-text-alpha: 0.38;
  --palette-foreground-secondary-text: 0, 0, 0;
  --palette-foreground-secondary-text-alpha: 0.54;
  --palette-foreground-thin-border: 33, 33, 33, 0.08;
  --palette-foreground-icon: 0, 0, 0;
  --palette-foreground-icon-alpha: 0.54;
  --palette-foreground-icons: 0, 0, 0;
  --palette-foreground-icons-alpha: 0.54;
  --palette-foreground-text: 0, 0, 0;
  --palette-foreground-text-alpha: 0.87;
  --palette-foreground-slider-min: 0, 0, 0;
  --palette-foreground-slider-min-alpha: 0.87;
  --palette-foreground-slider-off: 0, 0, 0;
  --palette-foreground-slider-off-alpha: 0.26;
  --palette-foreground-slider-off-active: 0, 0, 0;
  --palette-foreground-slider-off-active-alpha: 0.38;
  --palette-foreground-base-dark: 255, 255, 255;
  --palette-foreground-divider-dark: 255, 255, 255;
  --palette-foreground-divider-dark-alpha: 0.12;
  --palette-foreground-dividers-dark: 255, 255, 255;
  --palette-foreground-dividers-dark-alpha: 0.12;
  --palette-foreground-disabled-dark: 255, 255, 255;
  --palette-foreground-disabled-dark-alpha: 0.5;
  --palette-foreground-disabled-button-dark: 255, 255, 255;
  --palette-foreground-disabled-button-dark-alpha: 0.3;
  --palette-foreground-disabled-text-dark: 255, 255, 255;
  --palette-foreground-disabled-text-dark-alpha: 0.5;
  --palette-foreground-hint-text-dark: 255, 255, 255;
  --palette-foreground-hint-text-dark-alpha: 0.5;
  --palette-foreground-secondary-text-dark: 255, 255, 255;
  --palette-foreground-secondary-text-dark-alpha: 0.7;
  --palette-foreground-icon-dark: 255, 255, 255;
  --palette-foreground-icon-dark-alpha: 1;
  --palette-foreground-icons-dark: 255, 255, 255;
  --palette-foreground-icons-dark-alpha: 1;
  --palette-foreground-text-dark: 255, 255, 255;
  --palette-foreground-text-dark-alpha: 1;
  --palette-foreground-slider-min-dark: 255, 255, 255;
  --palette-foreground-slider-min-dark-alpha: 1;
  --palette-foreground-slider-off-dark: 255, 255, 255;
  --palette-foreground-slider-off-dark-alpha: 0.3;
  --palette-foreground-slider-off-active-dark: 255, 255, 255;
  --palette-foreground-slider-off-active-dark-alpha: 0.38;
  --palette-stroke: 224, 225, 226;
  --palette-border-chips-transparent: 237, 237, 237;
  --palette-icon-color-chips-transparent: 95, 99, 104, 1;
  --palette-placeholder-border: 224, 224, 224, 1;

  /**
   * Custom colors
   */
  --notification-error-color: 255, 58, 58;
  --notification-success-color: 46, 192, 76;
  --notification-warning-color: 253, 193, 9;
  --notification-info-color: 155, 155, 155;
  --palette-sidebar-backdrop: 100, 100, 100;
  --palette-warn-default: 176, 0, 32;
  --palette-background-footer: 33, 33, 33;
  --palette-icon-color: 51, 51, 51;
  --palette-tag-text-color: 104, 108, 117;
  --section-gray: #fff, rgb(219 229 243 / 40%);
  --section-primary: #{map.get(theme.$primary-palette, 200)};
  --section-dark: rgb(var(--palette-background-footer));
  --section-white: #fff;
  --advantage-icon: 104, 108, 117;
  --palette-blue-ci: 75, 128, 204;
  --breadcrumb-background-color: 255, 255, 255;
  --sub-headline-light-grey: #979797;
  --palette-placeholder-icon-color: 255, 255, 255;

  /**
  * Custom highlighting
  */
  --palette-highlight-default: #efdd16;
  --palette-highlight-default-rgb: 239, 221, 22;
}
