/* You can add global styles to this file, and also import other style files */

@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;
@import 'swiper/css/bundle';
@import 'css-star-rating/scss/star-rating';
@import 'libs/bau365/ui-shell/src/lib/styles/colors';
@import 'libs/bau365/ui-shell/src/lib/styles/devices';
@import 'libs/bau365/ui-shell/src/lib/styles/advertisements';
@import 'libs/shared/ui-typography/src/lib/styles/typography';
@import 'libs/shared/ui-grid/src/lib/styles/flexgrid';
@import 'libs/shared/ui-material-override/src/lib/styles/material-override';
@import 'libs/shared/ui-swiper-override/src/lib/styles/swiper-override';
@import 'libs/shared/ui-cookie-consent/src/lib/styles/cookie-consent';

html,
body {
  height: 100%;
}

html {
  body {
    margin: 0;

    &.error {
      .wrapper {
        mat-sidenav-content {
          &.mat-sidenav-content {
            background-color: rgb(var(--body-background-color)) !important;
          }
        }
      }
    }
  }
}

.sr-only {
  display: none;
}

.selector-placeholder {
  display: none;
}

.mw-100 {
  max-width: 100%;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.blurred {
  filter: blur(8px);
}

.sidenav {
  left: auto;
  max-width: 100%;
  right: 0;
  width: 240px;
}

.actions {
  padding-bottom: 0 !important;
  text-align: right;

  @media (max-width: 599px) {
    padding-top: 8px !important;
  }

  @at-root #{&}__link {
    color: #{map.get(theme.$primary-palette, 200)};
    cursor: pointer;
  }
}

.cdk-global-scrollblock {
  overflow: hidden !important;
  position: static;
}

@media print {
  .cdk-overlay-container {
    display: none;
  }

  .cdk-global-scrollblock {
    bottom: unset !important;
    left: unset !important;
    overflow: auto !important;
    position: relative;
    right: unset !important;
    top: unset !important;
  }

  .header {
    position: relative !important;
  }
}
