@use 'sass:map';
@use 'libs/shared/ui-material-override/src/lib/styles/theme' as theme;

.mat-mdc-checkbox-label {
  white-space: initial;
}

.mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background {
  background-color: #{map.get(theme.$primary-palette, 200)} !important;
}

.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent .mat-mdc-ripple-element,
.mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-accent .mat-mdc-ripple-element {
  background-color: #{map.get(theme.$primary-palette, 200)} !important;
}
